import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import useTranslation from '@modules/hooks/useTranslation';
import { setModalClose } from '@modules/utils/cookie';
import { getNoticeListAPI } from '@modules/api/home';
import { IsNoticeModalOpen } from '@modules/state/home';
import styles from './index.module.scss';

const Notice = () => {
    const { t } = useTranslation('home');

    const router = useRouter();

    const setIsNoticeModalOpen = useSetRecoilState(IsNoticeModalOpen);
    const { data, error } = getNoticeListAPI();

    const [activeId, setActiveId] = useState(0);

    const handlePrevClick = (e: any) => {
        e.stopPropagation();

        if (activeId === 0) return;

        setActiveId((activeId) => activeId - 1);
    };

    const handleNextClick = (e: any) => {
        e.stopPropagation();

        if (data === undefined) return;
        if (!Array.isArray(data.data)) return;

        if (activeId === data.data.length - 1) return;

        setActiveId((activeId) => activeId + 1);
    };

    const handleTodayCloseClick = () => {
        setModalClose('UNM');
        setIsNoticeModalOpen(false);
    };

    const handleContentsClick = (e: any) => {
        e.stopPropagation();

        if (data === undefined) return;
        if (!Array.isArray(data.data)) return;

        router.push(data?.data[activeId].popup_link);
    };

    useEffect(() => {
        if (error) {
            setIsNoticeModalOpen(false);
        }
        if (data && !Array.isArray(data.data)) {
            setIsNoticeModalOpen(false);
        }
    }, [data, error]);

    // useEffect(() => {
    //     if (fail) {
    //         setNoticeList([]);
    //         setIsNoticeModalOpen(false);
    //     }
    //
    //     if (success) {
    //         const { data } = success;
    //
    //         const tmp = [];
    //         for (let i = 0; i < data.length; i++) {
    //             tmp.push({ imageURL: data[i].popup_image, link: data[i].popup_link, exposure: data[i].exposure });
    //         }
    //         tmp.sort((i, j) => {
    //             return i.exposure - j.exposure;
    //         });
    //
    //         setNoticeList(tmp);
    //     }
    // }, [success, fail]);

    if (data === undefined) return null;
    if (!Array.isArray(data.data)) return null;

    return (
        <div role="presentation" onClick={() => setIsNoticeModalOpen(false)} className={styles.background}>
            <div className={styles.component}>
                <div role="presentation" onClick={(e) => e.stopPropagation()} className={styles.pagination}>
                    {data.data.map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            onClick={() => setActiveId(index)}
                            className={activeId === index ? styles.active : ''}
                        />
                    ))}
                </div>
                <div className={styles.content_area}>
                    <button type="button" name="prev" disabled={activeId === 0} onClick={handlePrevClick} />
                    <img
                        role="presentation"
                        src={data.data[activeId].popup_image}
                        width={480}
                        height={480}
                        alt="update"
                        onClick={handleContentsClick}
                    />
                    <button
                        type="button"
                        name="next"
                        disabled={activeId === data.data.length - 1}
                        onClick={handleNextClick}
                    />
                </div>
                <div className={styles.btn_area}>
                    <button type="button" onClick={handleTodayCloseClick} className={styles.today_close}>
                        {t('noToday')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Notice;
