import { useMutation, useQuery } from 'react-query';
import api from '@modules/api/index';
import type { ApiResponse } from '@modules/types/api';
import type { NoticeResponse } from '@modules/types/api/home';

const getNoticeList = async (): Promise<ApiResponse<NoticeResponse[] | {}>> => {
    const { data } = await api.post('/utils/popuplist', {});
    return data;
};

export const getNoticeListAPI = () => {
    return useQuery('noticeList', () => getNoticeList(), {
        retry: false,
    });
};

const STS = async (file: File, duration: number, speaker: string) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('duration', String(duration));
    formData.append('speaker', speaker);

    const { data } = await api.post('/utils/sound_experience', formData);
    return data;
};

export const mainSTSAPI = () => {
    return useMutation(({ file, duration, speaker }: { file: File; duration: number; speaker: string }) =>
        STS(file, duration, speaker),
    );
};
